<template>
  <div id="app">
    <TopMenu/>
    <AlertBox :alert='alert'/>
      <div class="container-fluid p-0 bg-dark" >
        <div class="col-12 bg-dark mb-1" v-show='isEditable'>
          <b-button variant="success" @click="addRowHandler" >Add Server</b-button>
        </div>
        <b-table
            id="fullTable"
            :fields="fields"
            :items="serverRecords"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            small
            bordered
            dark
            responsive
        >
          <template #cell(serverName)="data">
            <b-form-input v-if="data.item.isEdit" type="text" v-model="data.item.serverName"/>
            <span v-else>{{data.item.serverName}}</span>
          </template>
          <template #cell(ddePort)="data">
            <b-form-input v-if="data.item.isEdit" type="text" v-model="data.item.ddePort"/>
            <span v-else>{{data.item.ddePort}}</span>
          </template>
          <template #cell(connection)="data">
            {{ data.item.connection  }}
          </template>
          <template #cell(symbolNum)="data">
            {{ data.item.symbolNum  }}
          </template>
          <template #cell(company)="data">
            {{ data.item.company  }}
          </template>
          <template #cell(login)="data">
            {{ data.item.login  }}
          </template>
          <template #cell(edit)="data">
            <b-button @click="editRowHandler(data)" class="ml-1 mt-1 btn-sm">
              <span v-if="!data.item.isEdit"><feather type="settings"></feather></span>
              <span v-else><feather type="check"></feather></span>
            </b-button>
            <b-button @click="deleteRowHandler(data)" v-if="data.item.isEdit" class="ml-1 mt-1  btn-sm">
              <span><feather type="trash-2"></feather></span>
            </b-button>
          </template>
        </b-table>
    </div>

  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import TopMenu from '../components/TopMenu.vue'
import AlertBox from '../components/AlertBox.vue'


export default {
  name: 'App',
  components: {
    TopMenu,
    AlertBox
},
  data() {
      return {
          isEditable: true,
          sortBy: 'serverName',
          sortDesc: true,
          fields: [
            {
                key:"serverName",
                sortable: true,
            },
            {
                key:"ddePort",
                sortable: true,
                label: "DDE Port"
            },
            {
                key:"connection",
            },
            {
                key:"symbolNum",
            },
            {
                key:"company",
            },
            {
                key:"login",
            },
            {
                key:"edit",
            }
          ],
          serverRecords: [],      
          alert: {
            time: '',
            text: '',
            variant: 'info'
          },
          updateServerStatus: null
      }
  },
  mounted() {
    this.filterUser();
    this.fetchServerRecords();
    this.updateServerStatus = setInterval(()=>{
      if(!this.serverRecords.some(record => record.isEdit)) this.fetchServerRecords();
    }, 1000);
  },
  destroyed(){
    console.log('destroyed');
    clearInterval(this.updateServerStatus);
  },
  methods: {
    filterUser() {
      if(this.$store.getters.getSession.user.name != 'admin') {
        this.fields = this.fields.filter(field => field.key != 'edit');
        this.isEditable = false;
      }
    },
    fetchServerRecords() {
      this.$store.dispatch('fetchServerRecords').then((res) => {
        if(res.status == 200) {
          this.serverRecords = this.$store.getters.getServerRecords.map(item => ({...item, isEdit: false}));
        } else this.alert = {text:'failed to get server records, reson=' + res.data, variant: 'danger'};
      });
    },
    updateServerRecords() {
        this.$store.dispatch('updateServerRecords', this.serverRecords).then((res) => {
          if(res.status == 200) {
            this.alert = {text:'Update successful', variant: 'success'};
          } else this.alert = {text:res.data, variant: 'danger'};
        });
    },
    editRowHandler(data) {
      data.item.isEdit = !data.item.isEdit;
      if(!data.item.isEdit) {
        this.updateServerRecords();
      }
    },
    deleteRowHandler(data) {
      if(confirm("Delete this record?"))
      {
        const indexOfObject = this.serverRecords.findIndex(object => 
        { return object.id === data.item.id;});
        this.serverRecords.splice(indexOfObject, 1);
        this.updateServerRecords();
      }
    },
    addRowHandler() {
      let newRow = this.fields.reduce((a, c) => ({ ...a, [c.key]: null }), {});
      newRow.id = this.serverRecords.reduce((a,c) => c.id > a ? c.id : a, 0) + 1;
      newRow.isEdit = true;
      this.serverRecords.unshift(newRow);
    },
  },
  setup () {
    console.log("I am in setup!!!")
  }
}
</script>

<style>
  @import '../assets/styles/global.css';
</style>